import getCurrentEnvironment from 'utils/handler/getCurrentEnvironment'
import urls from 'utils/helpers/url'
import { temanSevaUrlPath } from 'utils/types/props'
const baseUrl = getCurrentEnvironment.apiBaseUrl
const baseUrlTemanSeva = getCurrentEnvironment.temanSevaApiBaseUrl

const utils = {
  menu: `${baseUrl}/menu`,
  cities: `${baseUrl}/city/fe-selector`,
  testimonials: `${baseUrl}/testimonials`,
  usage: `https://storage.googleapis.com/seva-storage/utils/how-to-use.json`,
  banner: `${baseUrl}/banner-image/homepage`,
  announcementBox: `${baseUrl}/announcement-box`,
  mobileHeaderMenu: `${baseUrl}/menu/mobile-web-top-menu`,
  mobileFooterMenu: `${baseUrl}/menu/mobile-web-bottom-menu`,
  search: `${baseUrl}/cars/search-bar`,
  supportedBrowser: `${baseUrl}/web-browser/check`,
  incomeList: `${baseUrl}/recommendations/get-income-list`,
  checkPromoCodeGias: `${baseUrl}/promo-code/status`,
  probe: 'https://probe.addpush.com/d/sub/',
  checkReferralCode: temanSevaUrlPath.checkRefCode,
  checkNIKAvailable: `${baseUrl}/customers/check-ktp-availability`,
  salesAgent: `${baseUrl}/salesman/tso-dso`,
  promoBanner: `https://www.seva.id/info/wp-json/v1/posts/promo/`,
}

const product = {
  recommendation: `${baseUrl}/recommendations/new-funnel`,
  cityRecommendation: `${baseUrl}/recommendations/get-city-by-model-name`,
  variant: `${baseUrl}/variants`,
  type: `${baseUrl}/cars/body-type-data`,
  carofTheMonth: `${baseUrl}/car-of-the-month`,
  pricing: `${baseUrl}/recommendations/get-price-min-max-by-city`,
  modelDetails: baseUrl + '/models/:id',
  modelPromoDetails: baseUrl + '/models/promo-model/:id',
  modelSpecification: baseUrl + '/models/model-variant-specifications/:id',
  variantSpecification: baseUrl + '/variants/specification-and-color/:id',
  variantDetails: baseUrl + '/variants/:id',
  carVideoReview: baseUrl + '/car-video-review',
  carCollection: baseUrl + '/cars/collection',
  carCatalog: `${baseUrl}/recommendations/car-catalog`,
  carBrandList: baseUrl + '/new-car/get-list-brand',
}

const usedCar = {
  usedCars: `${baseUrl}/used-car`,
  metaUsedCar: `${baseUrl}/used-car/meta`,
  uuid: `${baseUrl}/used-car/details/:uuid`,
  cityList: `${baseUrl}/used-car/city-list`,
  pricing: `${baseUrl}/used-car/get-price-car-min-max-by-city`,
  years: `${baseUrl}/used-car/get-years-car-min-max-by-city`,
  mileage: `${baseUrl}/used-car/get-mileage-car-min-max-by-city`,
  brandList: `${baseUrl}/used-car/get-list-brand`,
  usedCarsLeads: `${baseUrl}/used-car/submit-leads`,
  modelUsedCar: `${baseUrl}/used-car/get-model`,
  usedRecommendations: `${baseUrl}/used-car/used-car-recommendations`,
  usedNewCarRecommendations: `${baseUrl}/used-car/new-car-recommendations`,
  getCarCreditsSk: `${baseUrl}/used-car/get-car-credits-sk`,
}

const dealer = {
  dealerBranch: `${baseUrl}/branch`,
  detailBranch: `${baseUrl}/branch/detail`,
  listBrandNewCar: `${baseUrl}/branch/get-list-brand`,
}

const leads = {
  unverifiedLeadNew: `${baseUrl}/unverifiedLeads/new`,
  unverifiedLeadDealer: `${baseUrl}/unverifiedLeads/branch-pages`,
  customerAssistantDetails: `${baseUrl}/unverifiedLeads/csaDetails`,
  paIaInfo: `${baseUrl}${urls.internalUrls.paaIAInfo}`,
}

const omnicom = {
  check: `${baseUrl}/omnicom/check/:id`,
  updateLeads: `${baseUrl}/omnicom`,
  updateLeadsCM: `${baseUrl}/omnicom/cm`,
}

const asl = {
  posLocation:
    'https://storage.googleapis.com/seva-storage/utils/base-conf.json',
  wib: 'https://storage.googleapis.com/seva-storage/astra-siaga-lebaran/jadwal-imsak/wib.json',
  wita: 'https://storage.googleapis.com/seva-storage/astra-siaga-lebaran/jadwal-imsak/wita.json',
  wit: 'https://storage.googleapis.com/seva-storage/astra-siaga-lebaran/jadwal-imsak/wit.json',
  baleSantai:
    'https://storage.googleapis.com/seva-storage/astra-siaga-lebaran/bale-santai.json',
  poskoSiaga:
    'https://storage.googleapis.com/seva-storage/astra-siaga-lebaran/posko-siaga.json',
  bengkelSiaga:
    'https://storage.googleapis.com/seva-storage/astra-siaga-lebaran/bengkel-siaga.json',
}

const auth = {
  user: `${baseUrl}/customers/info`,
  refresh: `${baseUrl}/auth/refresh-token`,
  otp: `${baseUrl}/auth/otp`,
  otpFromWhatsapp: `${baseUrl}/auth/otp/whatsapp`,
  otpWhatsappCheckStatus: `${baseUrl}/auth/otp/whatsapp/check-status/:id`,
  otpFromSms: `${baseUrl}/auth/otp/messages`,
  otpVerification: `${baseUrl}/auth/verification`,
  checkRegistered: `${baseUrl}${urls.internalUrls.checkRegistered}`,
  createCustomer: `${baseUrl}${urls.internalUrls.createCustomerSeva}`,
  checkAsfinCustomer: `${baseUrl}/customers/check-asfin-customer`,
  login: `${baseUrl}/auth/login`,
  logout: `${baseUrl}/auth/logout`,
}

const loanCalculator = {
  specialRate: `${baseUrl}/loan-calculator/calculate`,
  insurance: `${baseUrl}/loan-calculator-v2/insurance/:modelId/:cityCode/:tenure`,
  loanPermutationIncludePromo: `${baseUrl}/loan-calculator-v2/calculate-included-promo`,
  loanPermutationIncludePromoV3: `${baseUrl}/loan-calculator-v3/calculate-included-promo`,
  loanPermutationAsuransiKombinasi: `${baseUrl}/loan-calculator-v2/calculate-asuransi-kombinasi`,
  loanPermutationAsuransiKombinasiV3: `${baseUrl}/loan-calculator-v3/calculate-asuransi-kombinasi`,
  finalDpValidation: `${baseUrl}/loan-calculator-v2/final-dp-validation/:variantId/:cityCode`,
  saveDraftCsa: `${baseUrl}/lead/save-draft-csa`,
}

const article = {
  mainArticle: `https://seva.id/wp-json/foodicious/latest-posts/`,
  subArticle: `https://seva.id/wp-json/seva/latest-posts/`,
  tagArticle: `https://seva.id/wp-json/seva-api/v1/post`,
}

const upload = {
  file: `${baseUrl}/customers/me/loan-documents`,
  ktpFile: `${baseUrl}/customers/ocr-ktp-new`,
  ktpFileV3: `${baseUrl}/customers/ocr-ktp-new-v3`,
  fileNew: `${baseUrl}/customers/me/loan-documents/new`,
}

const creditQualification = {
  single: `${baseUrl}/kualifikasi-kredit`,
  multi: `${baseUrl}/kualifikasi-kredit/multi`,
  instantApproval: `${baseUrl}/kualifikasi-kredit/ia`,
  instantApprovalResult: `${baseUrl}/customer-pre-approval/:id`,
  instantApprovalResultNew: `${baseUrl}/customer-pre-approval/new/:leadId`,
}

const ktp = {
  customer: `${baseUrl}/customers/get-ktp-existing`,
  customerSpouse: `${baseUrl}/customers/get-ktp-spouse-existing`,
  saveKtp: `${baseUrl}/customers/submit-ktp-new`,
  saveKtpSpouse: `${baseUrl}/customers/submit-ktp-spouse-only`,
  updateKtpCity: `${baseUrl}/customers/update-ktp-city-existing`,
}

const profile = {
  deleteAccount: `${baseUrl}/api/v1/archive-customer`,
  updateProfile: `${baseUrl}/customers/update-profile`,
}

const temanSeva = {
  register: `${baseUrlTemanSeva}/auth/registration`,
  checkTemanSeva: `${baseUrlTemanSeva}/auth/is-teman-seva`,
  profile: `${baseUrlTemanSeva}/auth/profile`,
  totalKomisi: `${baseUrlTemanSeva}/transaction/`,
  totalReferee: `${baseUrlTemanSeva}/total-referee/`,
  refereeList: `${baseUrlTemanSeva}/referee/list/:refCode`,
  refereeListSort: `${baseUrlTemanSeva}/referee/list/:refCode?sort=`,
}

const refinancing = {
  refinancingCarsBrand: `${baseUrl}/refinancing-car/brand`,
  refinancingCarsModel: `${baseUrl}/refinancing-car/models/:model`,
  refinancingCarsYear: `${baseUrl}/refinancing-car/years`,
  refinancingSecondLeadsForm: `${baseUrl}/refinancing/product-choice`,
  sendRefiContact: `${baseUrl}/refinancing/contact`,
  sendRefiQuestion: `${baseUrl}/refinancing/question`,
}

const promo = {
  promoCodeValidation: `${baseUrl}/promo/check-promo?promo-code=`,
  promoIAByCSAValidation: `${baseUrl}/lead/check-lead-by-origination?source-code=`,
  promoFile: `${baseUrl}/utilities/image-upload`,
  sendPromoLeads: `${baseUrl}/lead/submit-promo-redemption`,
}

const search = {
  newCar: `${baseUrl}/new-car/search-bar`,
  usedCar: `${baseUrl}/used-car/search-bar`,
}

const transactionHistory = {
  iaHistory: `${baseUrl}/lead/lead-submission-instant-approval`,
  kkHistory: `${baseUrl}/lead/lead-submission-no-approval`,
  draftCsa: `${baseUrl}/lead/get-draft-csa/:id`,
}

const EmployeePackage = {
  model: `${baseUrl}/cars/package-karyawan-list`,
  variantCredit: `${baseUrl}/cars/package-karyawan-by-variant`,
  listCompany: `${baseUrl}/api/v1/astra-company-list`,
  checkNpk: `${baseUrl}/api/v1/check-employee`,
}

const Instagram = {
  url: 'https://feeds.behold.so/Zz0BgCzTpzlVRgsdRKn2',
}

export const collections = {
  utils,
  product,
  article,
  auth,
  leads,
  loanCalculator,
  upload,
  creditQualification,
  ktp,
  profile,
  omnicom,
  asl,
  usedCar,
  temanSeva,
  refinancing,
  promo,
  search,
  dealer,
  transactionHistory,
  EmployeePackage,
  Instagram,
}
