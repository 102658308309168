export enum ToastType {
  Error = 'Error',
  Success = 'Success',
  Info = 'Info',
  ErrorPreApproval = 'ErrorPreApproval',
}

export enum PaymentType {
  MonthlyInstallment = 'monthlyInstallment',
  DownPayment = 'downPayment',
  CarModel = 'carModel',
}
export enum MinAmount {
  downPaymentAmount = 20000000,
  monthlyIncome = 3000000,
}

export enum ZIndex {
  'Overlay' = 10,
  'Appbar' = 20,
  'Menubar' = 30,
  'PageHeader' = 30,
  'WebAnnouncementBox' = 31,
  'FloatingIcon' = 35,
  'Modal' = 40,
  'Notification' = 50,
}

export enum UTMTags {
  UtmSource = 'utm_source',
  UtmMedium = 'utm_medium',
  UtmCampaign = 'utm_campaign',
  UtmContent = 'utm_content',
  UtmTerm = 'utm_term',
  UtmId = 'utm_id',
  Adset = 'adset',
  UtmReferrer = 'utm_referrer',
  UtmNotes = 'utm_notes',
}

export enum UnverifiedLeadSubCategory {
  SEVA_NEW_CAR_LP_LEADS_FORM = 'SEVNCLFH',
  SEVA_NEW_CAR_LP_ASL = 'SEVNCLASL',
  SEVA_NEW_CAR_HUBUNGI_KAMI = 'SEVNCCUS',
  SEVA_NEW_CAR_CAR_OF_THE_MONTH = 'SEVNCCOM',
  SEVA_NEW_CAR_PROMO_LEADS_FORM = 'SEVNCPLF',
  SEVA_NEW_CAR_PDP_LEADS_FORM = 'SEVNCVLF',
  SEVA_NEW_CAR_PLP_LEADS_FORM = 'SEVNCSPF',
  SEVA_NEW_CAR_SEARCH_WIDGET = 'SEVNCSWG',
  SEVA_NEW_CAR_OFFLINE_EVENT_FORM_COLD = 'SEVNCOEC',
  SEVA_NEW_CAR_OFFLINE_EVENT_FORM_HOT = 'SEVNCOEH',
  OTO_NEW_CAR_PDP_LEADS_FORM = 'OTONCPDP',
  OTO_NEW_CAR_PLP_LEADS_FORM = 'OTONCPLP',
  OTO_NEW_CAR_LP_LEADS_FORM = 'OTONCLPF',
  DEALER_LP_LEADS_FORM = 'SEVADEALERPAGE',
  DEALER_BRAND_LEADS_FORM = 'SEVADPB',
  DEALER_BRAND_LOCATION_LEADS_FORM = 'SEVADPBL',
  DEALER_DETAIL_LEADS_FORM = 'SEVADPD',
}

export enum Environment {
  Localhost = 'localhost',
  Development = 'development',
  Staging = 'staging',
  Production = 'production',
}

export type COMDataModel = {
  id: number
  modelId: string
  url: string
  startDate: string
  description: string
  modifiedBy: number
  status: boolean
  createdAt: string
  updatedAt: string
  isArchived: boolean
  variantId: string
  image?: string
  name: string
  carModel: {
    id: string
    brand: string
    model: string
    imageUrls: {
      main_color: string[]
      colors: string[]
      gallery: string[]
      brochure: string[]
      new_funnel_main_color: string[]
    }
    promoFlag: boolean | null
    productCode: string | null
    status: boolean
    otrStatus: null
    modelWordpressTag: string
  }
  carCatalog: {
    id: number
    make: string
    model: string
    base_product: string
    body_type: string
    variant_name: string
    body_type_url: string
    code: string
    colour_name: string | null
    description_bahasa: string
    description_english: string
    engine_capacity: number
    price_currency: string
    price_formatted_value: string
    price_value: number
    seat: number
    variant_title: string
    transmission: string
    seva_url: string
    length: number
    width: number
    height: number
    fuel_type: string
    model_id: string
    variant_id: string
    discount: number
    price_value_sby: number | null
    price_formatted_value_sby: string | null
    status: boolean
  }
  priceValue: number
  priceValueJkt: number
  price: number | null
}

export type COMData = {
  id: number
  brand: string
  order: number
  status: boolean
  model?: COMDataModel
  data: {
    folder: string
    image: string
    second_image?: string
    thumbnail: {
      image: string
      second_image?: string
    }
  }
}

export type COMDataTracking = {
  Car_Brand: string
  Car_Model: string
}

export enum RoundingStrategy {
  Round,
  Ceil,
  Floor,
}

export enum Marital {
  Married = 'Menikah',
  Single = 'Belum Menikah',
}

export enum Gender {
  Male = 'MALE',
  Female = 'FEMALE',
  Others = 'Others',
}

export enum CashFlow {
  Salary = 'Salary',
  Earnings = 'Earnings',
  Spouse = 'Spouse',
  Parents = 'Parents',
  SubMonthlySalary = 'MonthlySalary',
  SubOwnEarnings = 'OwnEarnings',
}

export enum Occupation {
  DesignerAndArtsProfessional = 'Designer & arts professional',
  DoctorAndMedicalWorker = 'Doctor & medical worker',
  LawProfessional = 'Law professional',
  StayAtHomeMother = 'Stay at home mother',
  PrivateCompanyEmployee = 'Employee (private company)',
  Other = 'Other',
  GovernmentEmployeePNS = 'Government employee (PNS)',
  InformalWorker = 'Informal worker',
  Student = 'Student',
  TeacherAndProfessorAndResearcher = 'Teacher, professor & researcher',
  Retiree = 'Retiree',
  FarmerAndFishermenAndBreeder = 'Farmer, fishermen & breeder',
  PolicemanAndArmyAndSecurity = 'Policeman, army & security',
  SelfEmployedAndDistributors = 'Self employed & distributors',
}

export enum OccupationNewer {
  KaryawanSwasta = 'Karyawan Swasta',
  WiraswastaDistributor = 'Wiraswasta Distributor',
  PetaniNelayanPeternak = 'Petani, Nelayan, & Peternak',
  Lainnya = 'Lainnya',
  IbuRumahTangga = 'Ibu Rumah Tangga',
  PegawaiNegeriSipil = 'Pegawai Negeri Sipil',
  PengajarPeneliti = 'Pengajar & Peneliti',
  PolisiAbriKeamanan = 'Polisi, Abri, & Keamanan',
  PelajarMahasiswa = 'Pelajar/Mahasiswa',
  DokterTenagaMedis = 'Dokter & Tenaga Medis',
  Pensiunan = 'Pensiunan',
  PekerjaInformal = 'Pekerja Informal',
  DesainerPekerjaSeni = 'Desainer & Pekerja Seni',
  Hukum = 'Hukum',
}

export enum Education {
  PrimarySchool = 'SD',
  SecondarySchool = 'SMP',
  HighSchool = 'SMA',
  BachelorsDegree = 'S1',
  MastersDegree = 'S2',
  DoctoratesDegree = 'S3',
  VocationalCertificate = 'D3',
}

export enum LoanRank {
  Green = 'Green',
  Yellow = 'Yellow',
  Red = 'Red',
}

export enum SurveyFormKey {
  Name = 'name',
  Age = 'age',
  GenderTmp = 'genderTmp',
  Gender = 'gender',
  Occupation = 'occupation',
  Education = 'education',
  City = 'city',
  CashFlow = 'cashFlow',
  TotalIncome = 'totalIncome',
  DownPayment = 'downPayment',
  HomeOwnership = 'homeOwnership',
  SeatNumber = 'seatNumber',
  TotalIncomeTmp = 'totalIncomeTmp',
  AgeTmp = 'ageTmp',
  DownPaymentTmp = 'downPaymentTmp',
  SpecialRateEnable = 'specialRateEnable',
  OldCalculatorResult = 'oldCalculatorResult',
}

export enum ContactFormKey {
  Name = 'name',
  PurchaseTime = 'purchaseTime',
  ContactTime = 'contactTime',
  PhoneNumber = 'phoneNumber',
  PhoneNumberValid = 'phoneNumberValid',
  PhoneNumberMiniSurvey = 'phoneNumberMiniSurvey',
  IsRegistered = 'isRegistered',
  CalculateLoan = 'calculateLoan',
  SpecialRateResult = 'specialRateResult',
  Dob = 'dob',
  CheckBox1 = 'checkBox1',
  CheckBox2 = 'checkBox2',
  SubmittedForm = 'submittedForm',
  IsLogin = 'isLogin',
  Email = 'email',
  NameTmp = 'nameTmp',
  Gender = 'gender',
  Marital = 'marital',
  ReferralCode = 'referralCode',
}

export enum PurchaseTime {
  Within2Weeks = 'Within 2 weeks',
  Within1Month = 'Within 1 month',
  Within2Months = 'Within 2 months',
  InOver2Months = 'In over 2 months',
}

export enum ContactTime {
  Morning = 'Morning',
  Afternoon = 'Afternoon',
  Evening = 'Evening',
}

export enum QueryKeys {
  LoanRank = 'loanRank',
  DpAmount = 'dpAmount',
  Tenure = 'tenure',
  MonthlyInstallment = 'monthlyInstallment',
  ModelId = 'modelId',
  VariantId = 'variantId',
  AdVariation = 'adVariation',
  UtmSource = 'utm_source',
  UtmMedium = 'utm_medium',
  UtmCampaign = 'utm_campaign',
  UtmContent = 'utm_content',
  UtmTerm = 'utm_term',
  UtmId = 'utm_id',
  AdSet = 'adset',
  UtmReferrer = 'utm_referrer',
  UtmNotes = 'utm_notes',
  CarBodyType = 'bodyType',
  CarBrand = 'brand',
  page = 'page',
  Id = 'id',
  QrType = 'qrtype',
  priceRangeGroup = 'priceRangeGroup',
  monthlyIncome = 'monthlyIncome',
  age = 'age',
  downPaymentAmount = 'downPaymentAmount',
  sortBy = 'sortBy',
}

export enum DownPaymentType {
  DownPaymentAmount = 'amount',
  DownPaymentPercentage = 'percentage',
}

export enum FunnelQueryKey {
  PaymentType = 'paymentType',
  DownPaymentType = 'downPaymentType',
  MonthlyInstallment = 'monthlyInstallment',
  DownPaymentAmount = 'downPaymentAmount',
  DownPaymentPercentage = 'downPaymentPercentage',
  PhoneNumber = 'phoneNumber',
  CarModel = 'carModel',
  Brand = 'brand',
  Age = 'age',
  BodyType = 'bodyType',
  Category = 'category',
  MinPrice = 'minPrice',
  MaxPrice = 'maxPrice',
  SortBy = 'sortBy',
}

export enum LocationStateKey {
  isCarRecommendationsEmpty = 'isCarRecommendationsEmpty',
  Reevaluated = 'reevaluated',
  IsCarRecommendationsEmpty = 'isCarRecommendationsEmpty',
  OtpSent = 'otpSent',
  IsFromLoginPage = 'isFromLoginPage',
  File = 'file',
  Channel = 'channel',
  IsFromPopularCar = 'isFromPopularCar',
  Base64 = 'base64',
  Blob = 'blob',
  loanRankCVL = 'loanRankCVL',
  PAAmbassadorError = 'paAmbassadorError',
  IsAutoCalculatePtbc = 'isAutoCalculatePtbc',
  IsAutoScrollFormPtbc = 'isAutoScrollFormPtbc',
}

export enum UploadChannel {
  Camera = 'camera',
  Gallery = 'gallery',
}

export enum ImageType {
  JPEG = 'image/jpeg',
  JPG = 'image/jpg',
  PNG = 'image/png',
  WEBP = 'image/webp',
}

export enum CustomerPreApprovalStatus {
  Failed = 'failed',
  Success = 'success',
  InProgress = 'in_progress',
  PendingResult = 'pending_result',
  NotStarted = 'not_started',
}

export enum CameraType {
  BackEN = 'back',
  BackBAHASA = 'belakang',
}

export enum InstallmentTypeOptions {
  ADDB = 'ADDB',
  ADDM = 'ADDM',
}

export enum PreApprovalFlowType {
  Ptbc = 'ptbc',
  New = 'new',
  PAAmbassador = 'pa-ambassador',
}

export enum TrackerFlag {
  Init = 'init',
  Sent = 'sent',
}

export interface CustomerRegister {
  fullName: string
  gender?: string
  dob: string
  email: string | null
  promoSubscription: boolean
  marital?: string
  referralCode?: string
}

export interface CitySelectorType {
  id: number
  cityName: string
  cityCode: string
  province: string
  status: boolean
  feSelector: boolean
  paWhitelist: boolean
  insuranceWilayahCode: string
  dsoCityCode: string
}

export type PAACustomerProps = {
  car: {
    brand: string
    model: string
    priceOtr: number
    variant: string
  }
  customer: {
    cityName: string
    fullName: string
    phoneNumber: string
  }
  financial: {
    angsuranType: string
    dpAmount: number
    flatRate: number
    leasing: string
    loanMonthlyInstallment: number
    loanTenure: number
    tpp: number
  }
}

export type PAALinkInfoProps = {
  customer: {
    city: CitySelectorType
    fullName: string
    phoneNumber: string
  }
} & Omit<PAACustomerProps, 'customer'>

export type PAAInfoStorageProps = {
  orderId: string
} & PAALinkInfoProps

export enum NewFunnelLoanRank {
  Red = 'Red',
  Yellow = 'Yellow',
  Green = 'Green',
  Grey = 'Grey',
}

export enum PageFrom {
  CarResult = 'car_results',
  CarResultDetails = 'car_result_details',
  CarResultVariant = 'car_result_variant',
}
export type CarBrandItemResponse = {
  makeName: string
  makeCode: string
  logoUrl: string
  isAstra: boolean
}
