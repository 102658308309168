var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"rkizqrHinYf5q2NXNeZnC"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'
import getCurrentEnvironment from 'utils/handler/getCurrentEnvironment'

Sentry.init({
  dsn: getCurrentEnvironment.sentryDSNKey,
  environment: getCurrentEnvironment.name,
  // Adjust this value in production, or use trac sSampler for greater control
  tracesSampleRate: 1.0,
  enabled: true,
  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  replaysOnErrorSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [
    new Sentry.Replay({
      // Additional Replay configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
    }),
    new Sentry.BrowserTracing(),
    Sentry.captureConsoleIntegration(),
  ],

  ignoreErrors: [
    'Error: Request failed with status code 400',
    'Error: This Suspense boundary received an update before it finished hydrating. This caused the boundary to switch to client rendering. The usual way to fix this is to wrap the original update in startTransition.',
    'Error: Retry attempt #1',
    'Hydration',
    'hydrating',
    'Suspense',
    'boundary',
    'https://reactjs.org/docs/error-decoder.html?invariant=422',
    'https://reactjs.org/docs/error-decoder.html?invariant=423',
    'https://reactjs.org/docs/error-decoder.html?invariant=425',
    'https://reactjs.org/docs/error-decoder.html?invariant=421',
    'FirebaseError: Installations: Could not process request. Application offline. (installations/app-offline)',
    'installations/app-offline',
    `Cannot read properties of undefined (reading 'domInteractive')`,
    'domInteractive',
    'Request failed with status code 401',
  ],

  // beforeSend(event,hint) {
  //   // Modify or drop the event here
  //   if (event.message?.includes('status code 400')) {
  //     // Don't send if status code 400
  //     return null
  //   } else {
  //     return event
  //   }
  // },

  beforeSend: (event, hint) => {
    const error = hint.originalException as Error
    const request = event.request
    if (
      error &&
      error.message &&
      error.message.includes(
        'status code 400' ||
          'This Suspense boundary received an update before it finished hydrating' ||
          'Retry attempt' ||
          'status code 401',
      )
    ) {
      // do not send known issue
      return null
    }
    if (request && request.headers) {
      const referer = request.headers.Referer || request.headers.referer

      if (referer && referer.includes('tiktok.com')) {
        return null
      }
    }
    return event
  },
})
